import {scrollbarSetToTop} from  "../components/Helper"
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import PartnerLogoSlider from "../components/PartnerLogoSlider";
import InnerBanner from "../components/InnerBanner";
import { Link } from "react-router-dom";
import FeedbackCard from "../components/cards/Feedback";
import ErrorBoundary from "../components/ErrorBoundary";
function AboutUs(){
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>XTLMS | About Us</title>
        </Helmet>
        
        <section className="about-section top-padding py-5 bg-cover" style={{backgroundImage: 'url(/images/new/slider-bg.png)'}}>
            <div className="container pb-sm-5">
                <div className="row align-items-center">
                    <div className="col-lg-6 mx-auto">                        
                        <div className="row g-4">
                            <div className="col-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-12 pt-lg-5">
                                        <img className="w-100 rounded-4" src="images/new/about-2.jpg" alt="" />
                                    </div>
                                    <div className="col-12">
                                        <div className="row justify-content-end">                                    
                                            <div className="col-10 col-lg-8 text-end">
                                                <img className="w-100 rounded-4" src="images/new/about-1.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-6 position-relative">                                    
                                <img className="w-100 rounded-4" src="images/new/about-3.jpg" alt="" />
                            </div>                                
                        </div>                                               
                    </div>
                    <div className="col-lg-5">
                        <div className="heading">
                            <h2 className="heading-lines heading-lg py-3">Enhance your skills with best <span>Online courses</span></h2>                            
                            <p>How promotion excellent curiosity yet attempted happiness Gay prosperous impression had conviction For every delay death ask to style Me mean able my by in they Extremity now strangers contained.</p>
                        </div>
                        <ul class="list-group border-0 mt-4">
                            <li class="list-group-item px-0 border-0 d-flex align-items-center">
                                <i class="lh-1 bi-patch-check-fill text-orange me-2"></i>Setup and installation takes less time
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex align-items-center">
                                <i class="lh-1 bi-patch-check-fill text-orange me-2"></i>Professional and easy to use software
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex align-items-center">
                                <i class="lh-1 bi-patch-check-fill text-orange me-2"></i>Perfect for any device with pixel-perfect design
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex align-items-center">
                                <i class="lh-1 bi-patch-check-fill text-orange me-2"></i>Setup and installation too fast
                            </li>
                        </ul>
                        <div className="mt-4">
                            <Link className="btn btn-outline-blue px-4 py-2" to={'/'}>Start Learning For Free</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>    
        
        


        <section className="py-5 position-relative bg-light about-section">            
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="heading-lines text-center pt-5">
                            Why <span> Choose </span>  Us ?
                        </h2>
                        <div className='home-futures text-center d-grid py-5'>
                            <div className='hf-item'>
                                <div className='hf-icon'>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="56px" width="56px" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M32 192 256 64l224 128-224 128L32 192z"></path><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M112 240v128l144 80 144-80V240m80 128V192M256 320v128"></path></svg>
                                </div>
                                <h4>Teacher</h4>
                                <p>At XT LMS, experienced educators support students with effective strategies to enhance performance and reduce stress, ensuring consistent improvement and good grades across all courses and classes year-round.</p>
                            </div>
                            <div className='hf-item'>
                                <div className='hf-icon'>
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" role="img" viewBox="0 0 24 24" height="56px" width="56px" xmlns="http://www.w3.org/2000/svg"><path d="M8.322 3.677L0 12l8.322 8.323L16.645 12zm7.371.01l-1.849 1.85 6.49 6.456-6.49 6.49 1.85 1.817L24 11.993Z"></path></svg>
                                </div>
                                <h4>Education</h4>
                                <p>XT LMS offers a relaxing, fast, and affordable learning experience with 24/7 access to online teaching tools, providing impactful education and a digital learning environment for higher education.</p>
                            </div>
                            <div className='hf-item'>
                                <div className='hf-icon-2'>
                                    <i class="bi bi-flower1 bi-icons"></i>
                                </div>
                                <h4>Resources</h4>
                                <p>With our best educational resources; study guides, lecture notes, practice exams, and supplement instructions are available for students who can access them at any time upon their availability.</p>
                            </div>
                            <div className='hf-item'>
                                <div className='hf-icon'>
                                    <i class="bi bi-joystick bi-icons"></i>
                                </div>
                                <h4>Trust</h4>
                                <p>XT LMS, a UAE-registered company, offers high-quality global educational services, adhering to the latest standards through its innovative online platform to keep pace with modern education systems.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 position-relative z-1 about-section">
            <div className="container py-sm-4">
                <div className="row align-items-center">
                    <div className="col-md-4 mx-auto">
                        <img className="w-100 rounded-4" src="/images/about/section-1.png" alt="" />
                    </div>
                    <div className="col-lg-7">
                        <div className="heading">
                            <h2 className="heading-lines py-3">Everything <span>You Need,</span> All Under a Single Platform!</h2> 
                        </div>
                        <ul class="list-group border-0 mt-0">
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-search bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Create a powerful brand identity</p>
                                    <p className="mb-0">with 100% white-labelled website, course landing pages & mobile apps.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-gear bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Automate enrolment</p>
                                    <p className="mb-0">flow, manage online & offline payments with invoices and receipts.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-diagram-3 bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Manage multiple batches</p>
                                    <p className="mb-0">branches, live & self-paced courses, webinars and more.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-question bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Host question bank</p>
                                    <p className="mb-0">driven mathematical & programming assessments.</p>
                                </div>
                            </li>
                        </ul>
                        <div className="mt-4">
                            <Link className="btn btn-outline-blue px-4 py-2" to={'/'}>Get Start</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 bg-light position-relative z-1 about-section inner-bg-3" style={{backgroundImage: 'url(/images/about/section-bg2.png)'}}>
            <div className="container py-sm-4">
                <div className="row align-items-center">                   
                    <div className="col-lg-7">
                        <div className="heading">
                            <h2 className="heading-lines py-3">Everything <span>You Need,</span> All Under a Single Platform!</h2>  
                        </div>
                        <ul class="list-group border-0">
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-bell bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Enhance learner engagement</p>
                                    <p className="mb-0">with in-built push notifications, email and SMS.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-people bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Boost overall learner satisfaction</p>
                                    <p className="mb-0">by ensuring course completions, attendance and discussions.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-patch-check bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Roll out digital certificates</p>
                                    <p className="mb-0">via a fully automated system with verification.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-envelope bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Get AI-Powered Real-time</p>
                                    <p className="mb-0">comprehensive reports on learners, courses and assessments.</p>
                                </div>
                            </li>
                        </ul>
                        <div className="mt-4">
                            <Link className="btn btn-outline-blue px-4 py-2" to={'/'}>Get Start</Link>
                        </div>
                    </div>

                    <div className="col-md-4 mx-auto">
                        <img className="w-100 rounded-4" src="/images/about/section-2.png" alt="" />
                    </div>
                </div>
            </div>
        </section>


        <section className="py-5 position-relative z-1">
            <div className="container py-sm-4">
                <div className="row align-items-center">
                    <div className="col-md-4 mx-auto">
                        <img className="w-100 rounded-4" src="/images/about/section-3.png" alt="" />
                    </div>
                    <div className="col-lg-7">
                        <div className="heading">
                            <h2 className="heading-lines py-3">Everything <span>You Need,</span> All Under a Single Platform!</h2>
                        </div>
                        <ul class="list-group border-0">
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-headset bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">24/7 Multi-Channel Customer Support</p>
                                    <p className="mb-0">Best-in industry</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-file-earmark bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">Drip content feature</p>
                                    <p className="mb-0">to keep your learners engaged & hooked for more.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-book bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">In-built DRM & Watermarking</p>
                                    <p className="mb-0">features to safeguard your course content.</p>
                                </div>
                            </li>
                            <li class="list-group-item px-0 border-0 d-flex gap-2 align-items-center">                                
                                <div className='hf-icon-new'>
                                    <i class="bi bi-bar-chart bi-icons-24"></i>
                                </div>
                                <div>
                                    <p className="mb-0 fw-bold fs-20">0% revenue sharing</p>
                                    <p className="mb-0">enabling you to profit from your course & program sales.</p>
                                </div>
                            </li>
                        </ul>
                        <div className="mt-4">
                            <Link className="btn btn-outline-blue px-4 py-2" to={'/'}>Get Start</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 bg-light position-relative z-1">
            <span className="pattern-square" style={{background:'url(./images/pattern-square.svg)'}}></span>
            <div className="container py-sm-4">
                <div className="row">
                    <div className="col-md-12 pb-3">
                        <h2 className="heading-lines text-center">Testimonials</h2>
                        <p className='text-center testmonial-item'>Our Students And Parents  <span>Feedback</span></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mx-auto">
                      <ErrorBoundary>
                            <FeedbackCard /> 
                      </ErrorBoundary>
                    </div>
                </div>
            </div>
        </section>
        
        </>
    )
}

export default AboutUs