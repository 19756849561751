import { useEffect, useState} from "react";
import { fetchData} from "../../components/Helper";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

function FeedbackCard() {
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    fetchData('feedback/yes', 'GET', '', false, false, (res) => {
      if (res.status && res.data) {
        setFeedback(res.data);
      }
    }, (err) => {});
  }, []);

  const responsiveOptions = {
    0: {
      nav: false,         
      dots: true,
      items: 1, // Number of items to show at 0px screen width
    },
    600: {
      nav: false,         
      dots: true,
      items: 1, // Number of items to show at 600px screen width
    },
    1000: {
      nav: false,         
      dots: true,
      items: 1, 
    },
  };


  return (  
    <>        
        {feedback.length > 0 && (
          <OwlCarousel items={feedback.length} className="owl-carousel owl-arrow-dots" nav margin={20} responsive={responsiveOptions}>
            {feedback.map((feedbackDetails, i) => (
              <div className="item" key={i}>
                <div className="feedback-item">
                  <div className="feedback-topbox">
                   {feedbackDetails.feedback_description}
                  </div>
                  <div className="feedback-bottombox">
                    <img src={feedbackDetails.feedback_image} className="" alt="XTLMS" />
                    
                    <div className="feedback-bottombox-meta">
                      <h5>{feedbackDetails.teacher_name}</h5>
                      <p>{feedbackDetails.course_name}</p> 
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        )}
        <OwlCarousel className="owl-carousel owl-arrow-dots" nav margin={20} responsive={responsiveOptions}>
          <div className="item">
            <div className="feedback-item">              
              <div className="feedback-topbox text-center">
                  As a parent, I’ve been really impressed with this learning management system. The interface is so intuitive that my kids were able to navigate it with minimal help. The design is clean and user-friendly, which means they spend less time figuring out how to use the platform and more time actually learning. Plus, the pricing is incredibly reasonable compared to other systems we’ve tried. It’s affordable without compromising on quality, which is a huge win for us.
              </div>
              <div className="feedback-bottombox">
                <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                <div className="feedback-bottombox-meta">
                  <h5>John Doe</h5>
                  <p>Independent Researcher</p> 
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="feedback-item">              
              <div className="feedback-topbox text-center">
                This LMS is a breath of fresh air! The simplicity of the system is fantastic—my children, who are not the most tech-savvy, have had no trouble using it. The user interface is well-designed, making everything from finding assignments to checking grades straightforward and easy. The best part? The pricing is very budget-friendly. It’s great to see a high-quality educational tool that doesn’t break the bank.
                </div>
                <div className="feedback-bottombox">
                <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                <div className="feedback-bottombox-meta">
                  <h5>John Doe</h5>
                  <p>Teacher</p> 
                </div>
              </div>
            </div>
          </div>
        <div className="item">
          <div className="feedback-item">           
              <div className="feedback-topbox text-center">
                  I can’t say enough good things about this learning management system. It’s incredibly simple to use, which is perfect for busy families like ours. The user interface is beautifully designed, with a layout that’s easy for students to understand and navigate. What really stands out, though, is the pricing. It’s one of the most cost-effective options we’ve come across, offering excellent value for its features. Definitely recommend it to other parents looking for a user-friendly and affordable solution.
              </div>
              <div className="feedback-bottombox">
                  <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                  <div className="feedback-bottombox-meta">
                      <h5>John Doe</h5>
                      <p>Independent Researcher</p>
                  </div>
              </div>
          </div>
        </div>

        <div className="item">
          <div className="feedback-item">           
              <div className="feedback-topbox text-center">
                  I can’t say enough good things about this learning management system. It’s incredibly simple to use, which is perfect for busy families like ours. The user interface is beautifully designed, with a layout that’s easy for students to understand and navigate. What really stands out, though, is the pricing. It’s one of the most cost-effective options we’ve come across, offering excellent value for its features. Definitely recommend it to other parents looking for a user-friendly and affordable solution.
              </div>
              <div className="feedback-bottombox">
                  <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                  <div className="feedback-bottombox-meta">
                      <h5>John Doe</h5>
                      <p>Independent Researcher</p>
                  </div>
              </div>
          </div>
        </div>

        
        <div className="item">
          <div className="feedback-item">            
              <div className="feedback-topbox text-center">
                This LMS has been a game-changer for our family. The system’s simplicity is a huge plus—everything is straightforward, so my kids can focus on their studies rather than wrestling with the software. The user interface is well-organized and visually appealing, which helps keep them engaged. On top of that, the pricing is fantastic. We’ve tried several other systems, and this one offers the best combination of ease-of-use and affordability.
              </div>
            
              <div className="feedback-bottombox">
                <img src="https://cdn-icons-png.flaticon.com/512/727/727399.png" className="" alt="XTLMS" />
                <div className="feedback-bottombox-meta">
                    <h5>John Doe</h5>
                    <p>Teacher</p>
                </div>
              </div>
            </div>
        </div>
    </OwlCarousel>
    </>  
  );
}

export default FeedbackCard;
