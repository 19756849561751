import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import FrontendFooter from "./Footer";
import Context from "./Context";
import FrontendHeader, { BackendHeader } from "./Header";
import FrontendSidebar, { BackendSidebar } from "./Sidebar";
import NotFound from "./NotFound";
import FrontComponent from "./FrontComponent";

function FrontendLayout(props){
    return(
        <>
            <FrontendHeader/>
                <Outlet />
            <FrontendFooter/>
        </>
    )
}

function FrontendProfileLayout(props){
    const navigate = useNavigate()
    const [context] = useContext(Context)
    const [token] = useState(localStorage.getItem('wt-token'));

    useEffect(() => {
        if (!token){
            navigate('/login')
        }
    }, [token, navigate]);

    return(
        <>
            { context && context.site && context.auth ? (context.auth.role_id !== 1 && context.auth.role_id !== 2) ?
            <>
            <FrontendHeader {...props}/>
            <section className="cources-section py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4  col-sm-12">
                            { context.auth && <FrontendSidebar role={context.auth.role_id} /> }
                        </div>
                        { context.auth && <FrontComponent {...props}/> }
                    </div>
                </div>
            </section>
            <FrontendFooter/>
            </>
            :
            <>
            <FrontendHeader {...props}/>
            <NotFound/>
            <FrontendFooter/>
            </> : '' }
        </>
    )
}

function BackendLayout(props) {
    const navigate = useNavigate()
    const [context] = useContext(Context)
    const [token] = useState(localStorage.getItem('wt-token'));
    useEffect(() => {
        if (!token){
            navigate('/login')
        }else{
            if (context && context.site && context.auth && context.auth.permission.length > 0 && context.auth.role_id === 2) {
                const matchingValues =  context && context.site && context.auth && context.auth.permission.filter(item => item === props.module );
                if(!matchingValues){
                    navigate('/NotFound');
                }
            }
        }
    }, [token, navigate, context, props.permission, props.module]);

    return (
        <>  
            { context && context.site && context.auth ? context.auth && (context.auth.role_id === 1 || ( context.auth.role_id === 2 && props.module && context.auth.permission.includes(props.module))) ?
            <>
    
            <BackendHeader/>
            <BackendSidebar/>
            <Outlet />
            </>
            : 
            <>
            <FrontendHeader />
            <NotFound/>
            <FrontendFooter/>
            </> : '' }
        </>
    )
}

export default FrontendLayout
export { BackendLayout, FrontendProfileLayout }