import {fetchData, initialFormState, showAlert, validateForm} from "../../components/Helper";
import {useEffect, useState, useContext} from "react";
import {createRoot} from "react-dom/client";
import Context from "../../components/Context";
import Datatables, {reloadUrlDataTable} from "../../components/Datatables";
import $ from "jquery";
import {useNavigate } from "react-router-dom";
import {Modal} from "react-bootstrap";

function MyWallet(props) {
    const [showModal, setShowModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [currencyType, setCurrencyType] = useState({});
    const [showRefModal, setShowRefModal] = useState(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [context] = useContext(Context);
    const [wallet, setWallet] = useState(0);
    const [topUp, setTopUp] = useState();
    const [cardNumber] = useState();
    const [cvv] = useState();
    const [expiryDate] = useState();
    const [checkpaymentStatus, setCheckpaymentStatus] = useState();
    const [paymentCharge, setPaymentCharge] = useState();
    const [processTopup, setProcessTopup] = useState(false);
    const [processBtn, setProcessBtn] = useState(true);
    const navigate = useNavigate();

    const [dt] = useState({
        dt_url: `get-transactions/${props.student_id ? props.student_id : context && context.auth && context.auth.id}`,
        dt_name: "transaction",
        dt_column: [
            {data: "payment_date", name: "payment_date", title: "Date", class: "mmw-100px"},
            {data: "name", name: "courses.name", title: "Course"},
            {
                data: "amount",
                name: "amount",
                title:
                    "Amount (" +
                    (context && context.site && context.site.currency ? context.site.currency : "EGP") +
                    ")",
            },
            {data: "payment_type", name: "payment_type", title: "Payment Type"},
            {data: "payment_method", name: "payment_method", title: "Payment Method"},
            {data: "payment_status", name: "payment_status", title: "Status"},
            {data: "description", title: "Description", class: "mmw-200px"},
        ],
        dt_column_defs: [
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>
                            {rowData.payment_status === "pending" ? (
                                <span className="text-warning fw-medium">Pending</span>
                            ) : (
                                ""
                            )}
                            {rowData.payment_status === "success" ? (
                                <span className="text-success fw-medium">Success</span>
                            ) : (
                                ""
                            )}
                            {rowData.payment_status === "failed" ? (
                                <span className="text-danger fw-medium">Failed</span>
                            ) : (
                                ""
                            )}
                            {rowData.payment_status === "refgen" ? (
                                <span className="text-info fw-medium">Reference Code Generated</span>
                            ) : (
                                ""
                            )}
                        </>
                    );
                },
            },
        ],
    });
    $("#btnPay").on("click", function () {
        $("#payForm").addClass("needs-validation");
        $("#cardForm").removeClass("needs-validation");
    });

    $("#btnCard").on("click", function () {
        $("#payForm").removeClass("needs-validation");
        $("#cardForm").addClass("needs-validation");
    });

    // $('#descriptionBtn').on('click', function () {
    //     // $("#refModal").modal("hide");
    //     // $("#DescriptionModel").modal("show");
    // });

    $("#referenceBtn").on("click", function () {
        // $("#DescriptionModel").modal("hide");
        setShowRefModal(true);
        setShowDescriptionModal(false);
        // $("#refModal").modal("show");
    });

    const checkTopup = (e) => {
        if (validateForm(e) && e.target.value > 15) {
            setTopUp(e.target.value);
            setProcessBtn(false);
        } else {
            setProcessBtn(true);
        }
    };

    const handleProcess = (e) => {
        if (validateForm(e) && topUp > 0) {
            setProcessTopup(true);
        }
    };

    const handleClose = () => {
        setShowRefModal(false);
    };
    const handleCurrencyChange = (e) => {
        let data = e.target.value;
        countries.forEach((country_data) => {
            if (country_data.id === parseInt(data)) {
                setCurrencyType(country_data);
            }
        });
    };
    const handleCloseDescription = () => {
        setShowDescriptionModal(false);
    };

    const DescriptionModal = () => {
        handleClose();
        setShowDescriptionModal(true);
    };

    const paymentModelClose = () => {
        setTopUp();
        setProcessBtn(true);
        setProcessTopup(false);
        setShowModal(false);
    };

    const handleTopUp = (e, paymentType) => {
        e.preventDefault();
        fetchData(
            `do-payment`,
            "POST",
            {
                topUp: topUp,
                cardNumber: cardNumber,
                cvv: cvv,
                expiryDate: expiryDate,
                paymentType: paymentType,
                studentId: props.student_id,
            },
            true,
            false,
            (res) => {
                if (res.status === true) {
                    setTopUp("");
                    setProcessBtn(true);
                    setProcessTopup(false);
                    setCheckpaymentStatus(res.data);
                    setShowModal(false);
                    setShowRefModal(true);
                    // $('#topUpModal').modal('hide');
                    // $('#refModal').modal('show');
                    reloadUrlDataTable(dt, `get-transactions/${props.student_id}`);
                }
            },
            (err) => {}
        );
    };

    const cancelTopup = (e) => {
        e.preventDefault();
        fetchData(
            `cancel-topup`,
            "POST",
            {refno: checkpaymentStatus.reference_code, studentId: props.student_id},
            true,
            false,
            (res) => {
                if (res.status === true) {
                    setCheckpaymentStatus(res.data);
                    setTopUp("");
                    setProcessBtn(true);
                    setProcessTopup(false);
                    reloadUrlDataTable(dt, `get-transactions/${props.student_id}`);
                }
                handleClose();
                // $('#refModal').modal('hide');
            },
            (err) => {}
        );
    };

    const verifyTopup = (e) => {
        e.preventDefault();
        fetchData(
            `verify-topup`,
            "POST",
            {refno: checkpaymentStatus.reference_code, studentId: props.student_id},
            true,
            false,
            (res) => {
                if (res.status === true) {
                    setTopUp("");
                    setProcessBtn(true);
                    setProcessTopup(false);
                    setCheckpaymentStatus(res.data);
                    setWallet(res.data.wallet_amount);
                    reloadUrlDataTable(dt, `get-transactions/${props.student_id}`);
                }
                handleClose();
                // $('#refModal').modal('hide');
            },
            (err) => {}
        );
    };

    useEffect(() => {
        // document.getElementById("topUpModal").addEventListener('hide.bs.modal', function (e) { 
        //     initialFormState('payForm', {})
        // })
        if (showModal) {
            initialFormState("payForm", {});
        }
        document.getElementById("group-title").innerHTML = "My Wallet";
        document.title = "XTLMS | My Wallet";
        if (context && context.auth) {
            let id = props.student_id ? props.student_id : context.auth.id;
            fetchData(
                `get-wallet/${id}`,
                "GET",
                "",
                true,
                false,
                (res) => {
                    if (res.status) {
                        setWallet(res.data && res.data.wallet_amount > 0 ? res.data.wallet_amount : 0);
                    }
                },
                (err) => {}
            );

            fetchData(
                `check-payment-status/${id}`,
                "GET",
                "",
                true,
                false,
                (res) => {
                    setCheckpaymentStatus(res.data);
                },
                (err) => {}
            );
            fetchData(
                `countries`,
                "GET",
                "",
                true,
                false,
                (res) => {
                    setCountries(res.data);
                    res.data.forEach((country_data) => {
                        if (country_data.currency_type === "EGP") {
                            setCurrencyType(country_data);
                        }
                    });
                },
                (err) => {}
            );

            fetchData(
                `get-payment-charge`,
                "GET",
                "",
                true,
                false,
                (res) => {
                    setPaymentCharge(res.data);
                },
                (err) => {}
            );

            reloadUrlDataTable(dt, `get-transactions/${id}`);
        }
    }, [dt, context, props.student_id, navigate, showModal]);

    const stripeCheckout = () => {
        fetchData(
            `stripe-checkout`,
            "POST",
            {topup: topUp,currency:currencyType, studentId: props.student_id},
            true,
            false,
            (res) => {
                if (res.status) {
                    window.location = res.data;
                }
            },
            (err) => {}
        );
    };

    const showTopUpModal = () => {
        if (
            !props.student_id ||
            (context && context.auth && context.auth.role_id === 5 && context.auth.id === props.student_id)
        ) {
            let msg = {message: "Please select any student.", status: false};
            showAlert(msg);
        } else {
            if (checkpaymentStatus && checkpaymentStatus.payment_status === "refgen") {
                // $('#refModal').modal('show');
                setShowRefModal(true);
            } else {
                setShowModal(true);
                // $('#topUpModal').modal('show');
            }
        }
    };
    return (
        <>
            <div className="card-body my-3">
                <div className="row mx-0 bg-dark py-3 rounded align-items-center fw-light position-relative overflow-hidden text-white fs-4">
                    <img
                        src="images/courses-bg.png"
                        className="position-absolute top-0 opacity-25 zindex-0 pointer-none"
                        alt="Topup"
                    />
                    <div className="col-sm-12 col-lg-4 zindex-1 position-relative">Your Balance</div>
                    <div className="col-6 col-lg-4 position-relative">
                        {context && context.site && context.site.currency ? context.site.currency : "EGP"} {wallet}
                    </div>
                    <div className="col-6 col-lg-4 text-end zindex-1 position-relative">
                        <button
                            type="button"
                            className="btn btn-orange fw-medium px-4"
                            title="Top up"
                            onClick={showTopUpModal}
                        >
                            Top up
                        </button>
                    </div>
                </div>
            </div>

            <div className="card rounded-10 border-0">
                <div className="card-header h5 py-3 border-0 rounded-0 bg-lightblue fw-bold">Transactions</div>
                <div className="card-body">
                    <div className="table-responsive p-1">
                        <Datatables dt_name="transaction" />
                    </div>
                </div>
            </div>

            {/* <div className="modal fade" id="topUpModal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg1">
                    <div className="modal-content">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title" id="exampleModalLabel">Top Up Your Wallet</h5>
                            <button type="button" id="closePaymentModel" onClick={paymentModelClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Close"></button>
                        </div>
                    </div>
                </div>
            </div> */}

            <Modal show={showModal} onHide={paymentModelClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Top Up Your Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        {processTopup ? (
                            <>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link text-dark fw-medium"
                                            data-bs-toggle="tab"
                                            data-bs-target="#payat"
                                            type="button"
                                            role="tab"
                                            aria-selected="true"
                                            title="Fawry"
                                        >
                                            Fawry
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link text-dark fw-medium active"
                                            data-bs-toggle="tab"
                                            data-bs-target="#stripe-payment"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            title="Stripe"
                                        >
                                            Stripe
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content border border-top-0 p-sm-4">
                                    <div className="tab-pane fade p-md-1" id="payat" role="tabpanel">
                                        <div className="ps-3 border-start border-primary border-3 mb-3 py-2 shadow-sm">
                                            <p className="fs-5">Top up Amount: {topUp} EGP</p>
                                            <div className="fs-15px pt-0 opacity-75 fw-normal mb-1">
                                                {`Fawry Charge (<= 5000 EGP):`} {paymentCharge.fawry_charge_2}% +{" "}
                                                {paymentCharge.fawry_charge} EGP
                                            </div>
                                            <div className="fs-15px pt-0 opacity-75 fw-normal mb-1">
                                                {`Fawry Charge (> 5000 EGP):`} {paymentCharge.fawry_charge_4}% +{" "}
                                                {paymentCharge.fawry_charge_3} EGP
                                            </div>
                                            <p className="fs-5 fw-medium mb-0">
                                                Total Amount:&nbsp;
                                                {parseFloat(topUp) > 5000
                                                    ? parseFloat(topUp) +
                                                      (parseFloat(paymentCharge.fawry_charge_4) * parseFloat(topUp)) /
                                                          100 +
                                                      parseFloat(paymentCharge.fawry_charge_3)
                                                    : parseFloat(topUp) +
                                                      (parseFloat(paymentCharge.fawry_charge_2) * parseFloat(topUp)) /
                                                          100 +
                                                      parseFloat(paymentCharge.fawry_charge)}{" "}
                                                EGP
                                            </p>
                                        </div>
                                        <div className="py-4">
                                            <h5 className="heading-lines fw-light pb-2 fs-6">Pay at Fawry</h5>
                                            <button
                                                type="button"
                                                className="btn btn-orange btn-lg w-100 mt-2"
                                                id="btnPay"
                                                onClick={(e) => handleTopUp(e, "payatfawry")}
                                                title="Generate Reference Code"
                                            >
                                                Generate Reference Code
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade p-md-1 active show"
                                        id="stripe-payment"
                                        role="tabpanel"
                                    >
                                        <div className="ps-3 border-start border-primary border-3 mb-3 py-2 shadow-sm">
                                            <p className="fs-5">
                                                {" "}
                                                Amount to be added to your wallet{" "}
                                                {(topUp )} EGP
                                            </p>
                                            <div className="row mb-3">
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-control "
                                                        required
                                                            name="currency"
                                                        onChange={(e) => handleCurrencyChange(e)}
                                                    >
                                                        {countries.map(function (country, i) {
                                                            return (
                                                                <>
                                                                    {" "}
                                                                    <option
                                                                        defaultValue={currencyType.id}
                                                                        value={country.id}
                                                                        selected={
                                                                            country.id === currencyType.id
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        {country.currency_type}
                                                                    </option>{" "}
                                                                </>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <p className="s-15px pt-0 opacity-75 fw-normal mb-1">
                                                {" "}
                                                Rate (best rate ) Foreign currency : { currencyType.increase_amount }{" "}
                                            </p>
                                            <p className="fs-5 fw-medium mb-2 mt-2">
                                                {" "}
                                                You are going to pay {currencyType.currency_type}{" "}
                                            </p>
                                            {/* <p className="fs-5">Top up Amount: {topUp} EGP</p> */}
                                            <div className="d-flex">
                                            <del>
                                                <div className="s-15px pt-0 opacity-75 fw-normal mb-1 ">
                                                    Stripe Charge: {paymentCharge.stripe_charge}% +{" "}
                                                    {paymentCharge.stripe_charge_2} EGP
                                                </div>
                                            </del>{" "}
                                            <span className="text-success ms-3">0</span>
                                            </div>
                                            <div className="mb-3">
                                                <small className="text-danger mt-0">For limited time</small>
                                            </div>
                                            <p className="fs-5 fw-medium mb-0">
                                                Total Amount:{" "} {parseFloat(topUp* currencyType.currency_price).toFixed(2)} {currencyType.currency_type}{" "}
                                                {/* {(
                                                    parseFloat(topUp) +
                                                    (parseFloat(paymentCharge.stripe_charge) * parseFloat(topUp)) /
                                                        100 +
                                                    parseFloat(paymentCharge.stripe_charge_2)
                                                ).toFixed(2)}{" "}
                                                EGP */}
                                            </p>
                                        </div>
                                        <div className="py-4">
                                            <h5 className="heading-lines fw-light pb-2 fs-6">Pay with Stripe</h5>
                                            <button
                                                type="button"
                                                className="btn btn-orange btn-lg w-100 mt-2"
                                                onClick={stripeCheckout}
                                                title="Pay with Stripe"
                                            >
                                                Pay with Stripe
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <form id="payForm" className="needs-validation p-3" noValidate>
                                <div className="input-group">
                                    <span className="input-group-text justify-content-center">EGP</span>
                                    <input
                                        type="number"
                                        autoComplete="off"
                                        step=".01"
                                        className="form-control"
                                        id="topUp"
                                        name="topUp"
                                        min={1}
                                        max={9999999}
                                        pattern="\d+(\.\d{1,2})?"
                                        onChange={(e) => checkTopup(e)}
                                        placeholder="Amount"
                                        required
                                    />
                                </div>
                                <span className="text-success fs-12px">Please enter an amount greater than 16.</span>

                                <button
                                    type="submit"
                                    className="btn btn-lg btn-orange w-100 mt-3"
                                    disabled={processBtn}
                                    id="btnProcess"
                                    onClick={handleProcess}
                                    title="Proceed"
                                >
                                    Proceed
                                </button>
                            </form>
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            {/** Reference code model */}
            {/* <div className="modal fade" id="refModal" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border-0 shadow-lg p-2">
                        <div className="d-flex justify-content-end align-items-center">
                            <button type="button" className="btn p-3 opacity-50" data-bs-dismiss="modal" aria-label="Close" title="Close">
                                <i className="bi bi-x-circle bi-6x"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

            <Modal show={showRefModal} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="">
                        <h2 className="display-6 mb-3">Reference Code</h2>
                        <h3 className="h2 text-blue mb-3 fw-bolder">
                            {" "}
                            {checkpaymentStatus ? checkpaymentStatus.reference_code : ""}
                        </h3>
                        <h4 className="fw-medium h4 mb-3">
                            Requested Amount: EGP {checkpaymentStatus ? checkpaymentStatus.amount : ""}
                        </h4>
                        <div className="mb-4 px-sm-5">
                            Use this code to pay at any Fawry POS or pay by card though My Fawry
                        </div>
                        <button
                            type="button"
                            onClick={cancelTopup}
                            className="btn btn-outline-danger me-3"
                            title="Cancel Topup"
                        >
                            Cancel Topup
                        </button>
                        <button
                            type="button"
                            onClick={verifyTopup}
                            className="btn btn-blue fw-medium px-4"
                            title="Verify Topup"
                        >
                            Verify Topup
                        </button>
                        <div className="mt-5">
                            <span id="descriptionBtn" onClick={DescriptionModal} className="text-blue h6" role="button">
                                How to pay at Fawry POS?
                            </span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showDescriptionModal} onHide={handleCloseDescription}>
                <Modal.Header closeButton>
                    {" "}
                    <Modal.Title>How to pay at Fawry POS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <img src="/images/logo.png" alt="XT LMS" className="w-100" />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
            {/** Description Model */}
            {/* <div className="modal fade" id="DescriptionModel" tabIndex="-1" aria-labelledby="descriptionModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="d-flex ps-3 justify-content-end1 align-items-center">
                            <button type="button" id="referenceBtn" className="btn btn-lg opacity-50" title="Back">
                                <i className="bi-arrow-left-circle-fill bi-3x"></i>
                            </button>
                            <h5 className="modal-title" id="descriptionModalTitle">How to pay at Fawry POS</h5>
                            <button type="button" className="btn ms-auto p-3 opacity-50" data-bs-dismiss="modal" aria-label="Close" title="Close">
                                <i className="bi bi-x-circle bi-6x"></i>
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default MyWallet;
