import { Link } from "react-router-dom";

function TeacherCard(props){
    console.log(props)
    return(
        <>
        {/* <div className="col-md-6 col-lg-4">
            <div className="course-item d-flex flex-column h-100">
                <div className="ratio ratio-4x3 bg-light">
                    <img src={props.teacher.user_details.image} className="w-100 h-100 img-cover" alt={props.teacher.name}/>
                </div>
                <span className="course-tag">{props.teacher.subject}</span>
                <div className="p-3 d-flex flex-column flex-fill">
                    <h6 className="course-heading">{props.teacher.name}</h6>
                    <p className="course-description">
                    erewre r{props.teacher.user_details.brief}
                    </p>
                    <div className="mt-auto pt-2 d-flex justify-content-between align-items-center">
                        <Link to={`/teacher-info/${props.teacher.id}`} className="btn btn-outline-blue w-100" title="View More">View More</Link>
                    </div>
                </div>
            </div>
        </div> */}
        
        <div className="col-lg-10 col-xl-6">
            <div className="card border-0 shadow-sm p-2">
                <div className="row g-0">
                    <div className="col-md-4">
                        <img src={props.teacher.user_details.image} className="w-100 h-100 rounded-3 img-cover" alt={props.teacher.name}/>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body d-flex flex-column h-100">
                            <div className="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                <div>
                                    <h5 className="card-title fw-semibold mb-0">{props.teacher.name}</h5>
                                    <p className="small fw-medium opacity-75 mb-2 mb-sm-0">Professor at Sigma College</p>
                                </div>
                                <span className="h6 fw-medium">5.0<i class="lh-1 ms-1 bi-star-fill text-warning"></i></span>
                            </div>
                            <p className="text-truncate-2 line-clamp-2 mb-3">{props.teacher.user_details.brief}</p>
                            <div className="d-sm-flex justify-content-sm-between mt-auto align-items-center">
                                <ul className="list-inline mb-0 mt-3 mt-sm-0">
                                    <li className="list-inline-item"> 
                                        <a className="mb-0 me-1 text-facebook" href="#"><i className="bi-facebook fs-16px"></i></a> 
                                    </li>
                                    <li className="list-inline-item"> 
                                        <a className="mb-0 me-1 text-instagram-gradient" href="#"><i className="bi-whatsapp fs-16px"></i></a> 
                                    </li>
                                    <li className="list-inline-item"> 
                                        <a className="mb-0 me-1 text-twitter" href="#"><i className="bi-instagram fs-16px"></i></a> 
                                    </li>
                                    <li className="list-inline-item"> 
                                        <a className="mb-0 text-linkedin" href="#"><i className="bi-youtube fs-16px"></i></a> 
                                    </li>
                                </ul>
                                <Link to={`/teacher-info/${props.teacher.id}`} className="enroll-link" title="View More">View More <i className="fs-4 lh-1 bi-arrow-right-short"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default TeacherCard